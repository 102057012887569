import { useTranslation } from "hooks/useTranslation";
import WhiteButton from "./WhiteButton";
import { MouseEvent } from "react";
import useChat from "hooks/useChat";
import { useNavigate } from "react-router-dom";
import { PATHS } from "constants/routes";
import { SmChatIcon } from "assets/icons";

const BizChatButton = ({ identity }: { identity?: string }) => {
  const { onOpenConversationWithIdentity } = useChat();
  const t = useTranslation();
  const navigate = useNavigate();

  const onClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (identity) {
      navigate(PATHS.messages);
      onOpenConversationWithIdentity(identity);
    }
  };

  return (
    <WhiteButton
      onClick={onClickButton}
      className="send-msg-btn"
      endIcon={<SmChatIcon />}
    >
      {t("chat_now")}
    </WhiteButton>
  );
};

export default BizChatButton;
