import dayjs from "dayjs";

export const FREE_PACKAGE = "Free";

export const isValidPlan = (plan: any): boolean => {
  if (!plan) return false;

  const now = dayjs();
  return (
    plan.is_valid &&
    plan.plan_name &&
    plan.plan_name !== FREE_PACKAGE &&
    dayjs(plan.end_date).isAfter(now)
  );
};
