import { FillInfoIcon } from "assets/icons";
import Tooltip from "./Tooltip";
import { XsTextMd } from "components/Text";
import { useTranslation } from "hooks/useTranslation";
import { useState } from "react";
import { Dialog, Stack } from "@mui/material";
import { TextMd } from "components/text/Text";
import SmText from "components/text/SmText";
import { GreenButton } from "components/button";

const VerifyRFQTooltip = ({ isVerified }: { isVerified: boolean }) => {
  const t = useTranslation();
  const [showDialog, setShowDialog] = useState(false);

  if (!isVerified) return null;

  const onCloseDialog = () => setShowDialog(false);

  const onOpenDialog = () => setShowDialog(true);

  return (
    <>
      <Tooltip
        title={t(
          isVerified ? "sourcing_request_is_verified_by_freshdi" : "verifying"
        )}
      >
        <XsTextMd className="status-label green-label">
          {t("confirmed_by_buyer")}
          <FillInfoIcon onClick={onOpenDialog} style={{ cursor: "pointer" }} />
        </XsTextMd>
      </Tooltip>

      <Dialog open={showDialog} onClose={onCloseDialog}>
        <Stack p={{ xs: 2, md: 3 }} spacing={2}>
          <TextMd>{t("confirmed_by_buyer")}</TextMd>
          <Stack spacing={1}>
            <SmText>{t("confirmed_by_buyer_description")}</SmText>
            <ul style={{ paddingInlineStart: 24 }}>
              <li>
                <SmText>{t("confirmed_by_buyer_point_1")}</SmText>
              </li>
              <li>
                <SmText>{t("confirmed_by_buyer_point_2")}</SmText>
              </li>
              <li>
                <SmText>{t("confirmed_by_buyer_point_3")}</SmText>
              </li>
            </ul>
          </Stack>
          <SmText>{t("confirmed_by_buyer_note")}</SmText>

          <Stack alignItems="flex-end">
            <GreenButton onClick={onCloseDialog}>{t("ok")}</GreenButton>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default VerifyRFQTooltip;
