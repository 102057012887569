import { Typography, TypographyProps } from "@mui/material";

const baseProps = {
  fontSize: { xs: 14, md: 16 },
};

const Text = (props: TypographyProps) => {
  return <Typography {...baseProps} {...props} />;
};

export const TextMd = (props: TypographyProps) => {
  return <Text fontWeight={500} {...props} />;
};

export default Text;
