import { createTheme } from "@mui/material/styles";
import { COLORS } from "constants/layout";

export const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),

    body1: {
      fontSize: 16,
      "@media (min-width: 600px)": {},
    },
    body2: {
      fontSize: 14,
      "@media (min-width: 600px)": {},
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: COLORS.green[600],
          "&.Mui-checked": {
            color: COLORS.green[600],
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          color: "initial",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: COLORS.gray[600],
          "&.Mui-checked": {
            color: COLORS.green[600],
          },
        },
      },
    },
  },
});
