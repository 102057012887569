import { SUPPLIER_PLANS } from "constants/data";
import { useMemo } from "react";
import { Img } from "./Img";

export const PlanChip = ({
  plan,
  height = 15,
}: {
  plan?: string;
  height?: number;
}) => {
  const currentPlan = useMemo(
    () => SUPPLIER_PLANS.find((p) => p.label === plan),
    [plan]
  );

  if (!currentPlan?.label2) return null;

  return (
    <Img
      src={currentPlan.planIcon}
      alt="icon"
      height={height}
      className="plan-img"
    />
  );
};
