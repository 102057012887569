import { Stack, styled } from "@mui/material";

const StyledRFQ = styled(Stack)(({ theme }) => ({
  position: "relative",
  height: "100%",
  boxSizing: "border-box",
  borderRadius: 8,
  border: "1px solid #EAECF0",
  padding: "8px 16px",
  backgroundColor: "#fff",
  "&.is-seen": {
    backgroundColor: "#F2F4F7",
  },
  ".rfq_image": {
    width: 120,
    height: 120,
    objectFit: "cover",
  },
  ".title": {
    maxWidth: "calc(100% - 32px)",
  },
  ".request-by": {
    color: "var(--gray-color)",
    span: {},
    // maxWidth: "50%",
    whiteSpace: "nowrap",
  },
  ".details": {
    fontSize: 13,
    color: "var(--gray-900)",
  },
  ".customer-name": {
    maxWidth: 74,
  },
  ".avatar": {
    width: 16,
    height: 16,
    objectFit: "cover",
    borderRadius: "50%",
  },
  ".rfq-detail": {
    ".country-box": {
      border: "none",
    },
  },
  ".quote_button": {
    maxWidth: "auto",
    lineHeight: 1.25,
  },
  ".time-box p": {
    fontSize: 13,
    color: "#27272A",
  },
  ".tooltip-icon img": {
    minWidth: 16,
  },
  ".info-text": {
    color: "var(--gray)",
    span: {
      color: "#27272A",
    },
  },
  ".attachment": {
    width: 48,
    height: 48,
    minWidth: 48,
  },
  ".action-icons": {},
  ".crown-icon": {
    width: 12,
  },
  ".verify-icon": {
    height: 12,
  },
  [theme.breakpoints.down("md")]: {
    // padding: 16,
    ".quote_button": {},
    ".rfq-detail": {
      //   maxWidth: "100%",
    },
  },
}));

export default StyledRFQ;
