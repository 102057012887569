import { SVGProps } from "react";

export function StarIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={17}
      viewBox="0 0 18 17"
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M9 0L12.0593 5.21592L18 6.49342L13.95 10.9945L14.5623 17L9 14.5659L3.43769 17L4.05 10.9945L0 6.49342L5.94073 5.21592L9 0Z"
        fill="#FFCB45"
      />
    </svg>
  );
}

export function EmptyStarIcon({
  color = "#667085",
  xmlns = "http://www.w3.org/2000/svg",
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={17}
      viewBox="0 0 18 17"
      xmlns={xmlns}
      fill="none"
      {...props}
    >
      <path
        d="M9 0L12.0593 5.21592L18 6.49342L13.95 10.9945L14.5623 17L9 14.5659L3.43769 17L4.05 10.9945L0 6.49342L5.94073 5.21592L9 0Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}
