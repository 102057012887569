import { Button, styled } from "@mui/material";
import { COLORS } from "constants/layout";

const WhiteButton = styled(Button)(({ theme }) => ({
  textTransform: "initial",
  borderRadius: 8,
  fontWeight: 500,
  padding: "8px 16px",
  fontSize: 14,
  border: `1px solid ${COLORS.green[600]}`,
  color: COLORS.green[600],
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    padding: "6px 10px",
  },
}));

export default WhiteButton;
