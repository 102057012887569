import { ButtonProps, styled } from "@mui/material";
import ButtonBase from "./BaseButton";

const GreenButton = (props: ButtonProps) => {
  return <StyledAcceptButton {...props} />;
};

const StyledAcceptButton = styled(ButtonBase)(({ theme }) => ({
  fontWeight: 500,
  color: "#fff",
  "&": {
    backgroundColor: "var(--sub-color)",
  },
  "&:hover": {
    backgroundColor: "var(--green-800)",
  },
  "&.Mui-disabled": {
    backgroundColor: "#D0D5DD",
    color: "#fff",
  },
  fontSize: 14,
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
    padding: "6px 10px",
  },
}));

export default GreenButton;
