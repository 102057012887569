import { Typography, TypographyProps } from "@mui/material";

const baseProps = {
  fontSize: { xs: 12, md: 14 },
};

const SmText = (props: TypographyProps) => {
  return <Typography {...baseProps} {...props} />;
};

export const SmTextMd = (props: TypographyProps) => {
  return <SmText fontWeight={500} {...props} />;
};

export default SmText;
