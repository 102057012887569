export const BREAKPOINT_VALUES = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1440,
  xl: 1530,
};

export const MENU_WIDTH = {
  expanded: 270,
  collaped: 80,
  collapedMobile: 52,
};

export const AUTO_PLAY_ITEM_TIME = 5000;

export const MAX_NOTI_CHAR_QLT = 99;

export const MAX_CHAT_CHARACTER_LEN = 1000;

export const MIN_PRODUCT_DESC_CHARACTER_LEN = 500;

export const DIFF_MONTH_TO_SHOW_TIP_RFQ_CATE = 3;

export const SM_LISTING_BREAKPOINT = {
  480: {
    slidesPerView: 1,
  },
  640: {
    slidesPerView: 5,
    spaceBetween: 8,
  },
};

export const LISTING_FILTER_WIDTH = 200;

export const COLORS = {
  green: {
    25: "#EDFFF4",
    50: "#E3FFEE",
    500: "#019c3c",
    600: "#008934",
    700: "#008934",
    800: "#025020",
  },
  brand: {
    25: "#edfff4",
    500: "#019c3c",
    700: "#027b30",
  },
  gray: {
    50: "#f9fafb",
    100: "#f2f4f7",
    200: "#EEEEEE",
    300: "#d0d5dd",
    400: "#98A2B3",
    500: "#667085",
    600: "#475467",
    700: "#344054",
    800: "#1d2939",
    900: "#101828",
  },
  bGray: {
    50: "#f8f9fc",
    500: "#4e5ba6",
    600: "#3e4784",
  },
  yellow: {
    25: "#fffaeb",
    50: "#FFFAEB",
    100: "#fef0c7",
    500: "#f79009",
    600: "#DC6803",
  },
  blue: {
    50: "#F0F9FF",
    700: "#026aa2",
  },
  error: {
    50: "#fef3f2",
    500: "#f04438",
    600: "#D92D20",
    700: "#b42318",
  },
  warning: {
    900: "#7A2E0E",
  },
  app: {
    bg: "#f9fafb",
  },
};

export const LISTING_CARD_LIMIT = {
  rfq: 21,
  buyer: 20,
};

export const SCROLL_TOP_POSITIONS = {
  desktopHeader: 69,
  mobileHeader: 50,
};

export const CHAT_LAYOUT = {
  delayScrollBottom: 100,
  chatContainerWidth: 300,
  expandedChatContainerWidth: 777,

  conversationsLimit: 10,
};

export const MAX_FILE_SIZE_MB = 10;

export const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
];
