import { Stack, styled } from "@mui/material";
import { Img } from "../Img";
import { SmText, SmTextMd, XsText, XsTextMd } from "../Text";
import { DIRECT_PATHS, SEARCH_PARAMS } from "constants/routes";
import TimeDiff from "../TimeDiff";
import dayjs from "dayjs";
import CountryFlag from "../CountryFlag";
import { useIntl } from "react-intl";
import Row from "../Row";
import { ElementType } from "react";
import { formatImg } from "helpers/format";
import { BuyerInfosType } from "types/buyer";
import { COLORS } from "constants/layout";
import { ALink, Link } from "components/link";
import { StarRating } from "components/rating";
import BuyerVerfifyTooltip from "components/tooltip/BuyerVerifyTooltip";
import { objectToQueryString } from "helpers/search";

const BuyerInfors = ({
  company,
  nameComponent = "p",
  fullLink = false,
  disabledLink = false,
}: {
  company?: BuyerInfosType;
  nameComponent?: ElementType;
  fullLink?: boolean;
  disabledLink?: boolean;
}) => {
  const t = useIntl();
  const {
    customer_name,
    country_code,
    last_active,
    customer_id = "",
    country,
    company_logo,
    salutation,
    position,
    num_posted_rfq = 0,
    trust_star = 0,
    is_biz_verified,
    is_verified_work_email,
  } = company || {};
  const NOW = dayjs();
  const buyerLink = !disabledLink
    ? DIRECT_PATHS.buyerDetail(customer_id, fullLink)
    : undefined;

  return (
    <StyledBaseBuyerInfors direction="row" alignItems="flex-start" spacing={1}>
      {company_logo && (
        <Img
          src={formatImg(company_logo, 1)}
          alt="img"
          className="company_img"
        />
      )}
      <Stack
        spacing={0.5}
        flex={1}
        maxWidth={`calc(100% - ${company_logo ? "48px" : "0px"})`}
      >
        {customer_name && (
          <ALink href={buyerLink}>
            <SmTextMd
              className="company_name ellipsis-text"
              component={nameComponent}
            >
              {salutation ? `${salutation}. ` : ""}
              {customer_name}
              {position && <span> ({position})</span>}
            </SmTextMd>
          </ALink>
        )}

        {trust_star > 0 && (
          <Row spacing={1} className="rating-box">
            <StarRating
              readOnly
              value={trust_star}
              sx={{ ".MuiRating-icon svg": { width: 16, height: 15 } }}
            />
            <SmText color="#FFCB45">{trust_star.toFixed(1)}</SmText>
          </Row>
        )}

        <Row spacing={0.5} mb={0.5} minHeight={12}>
          <BuyerVerfifyTooltip
            data={{ is_biz_verified, is_verified_work_email }}
          />
          <CountryFlag {...{ country_code, country }} />
        </Row>

        {last_active && (
          <XsText color={COLORS.gray[600]}>
            {t.formatMessage(
              { id: "active_time" },
              {
                time: (
                  <TimeDiff
                    endTime={NOW.format()}
                    startTime={dayjs(last_active).format()}
                  />
                ),
              }
            )}
          </XsText>
        )}

        {num_posted_rfq > 0 && (
          <Link
            to={`${DIRECT_PATHS.buyerDetail(customer_id)}?${objectToQueryString(
              { [SEARCH_PARAMS.tab]: "rfq" }
            )}`}
          >
            <XsTextMd className="status-label orange-label">
              {t.formatMessage(
                { id: "qlt_rfqs_posted" },
                { quantity: num_posted_rfq }
              )}
            </XsTextMd>
          </Link>
        )}
      </Stack>
    </StyledBaseBuyerInfors>
  );
};

const StyledBaseBuyerInfors = styled(Stack)({
  fontSize: 16,
  width: "100%",
  ".company_name": {
    color: "#1D2939",
    span: { color: COLORS.gray[600], fontSize: 12, fontWeight: 400 },
  },
  ".company_img": {
    width: 40,
    height: 40,
    objectFit: "cover",
    borderRadius: "50%",
  },
  ".flag": {
    width: 16,
    height: 16,
  },
  ".active-text": {
    color: "var(--gray-600)",
  },
});

export default BuyerInfors;
